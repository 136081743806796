import Axios from "axios";
import jwtDecode from "jwt-decode";

const baseUrl = process.env.REACT_APP_API;
const extBaseUrl = process.env.REACT_APP_EXT_API;
const dsBaseUrl = process.env.REACT_APP_DATA_SOURCE;
const discoveryBaseUrl = process.env.REACT_APP_DISCOVERY_SOURCE;
const cdnBaseUrl = process.env.REACT_APP_CDN_API;

const axios = Axios.create({
  baseURL: baseUrl,
  timeout: 5000,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});

// const STATS_API = `${dsBaseUrl}/api/discovery`;
const OPTIONS_API = `${discoveryBaseUrl}/api/symbol_with_options`;
const RECENT_STREAM_API = `${dsBaseUrl}/api/stream_bk`;
const HALT_LIST_API = `${dsBaseUrl}/api/halts`;

class API {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  emit() {
    console.info("API.emit - ", arguments);
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err && err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  login = (email, password) => {
    const header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/auth/login`, header)
        .then(async (response) => {
          let data = await response.json();
          if (data.access_token) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  datasourceLogin = () => {
    const header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/auth/ds/login`, header)
        .then(async (response) => {
          let data = await response.json();
          if (data.success) {
            this.setDatasourceSession(data.token);
            resolve(data);
          } else {
            this.setDatasourceSession("");
            reject(data.error);
          }
        })
        .catch((error) => {
          this.setDatasourceSession("");
          reject(error);
        });
    });
  };

  signup = ({ email, password, source }) => {
    const header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        source,
      }),
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/auth/signup`, header)
        .then(async (response) => {
          let data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  verify = (email) => {
    const header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
      body: JSON.stringify({
        email,
      }),
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/auth/verify_email`, header)
        .then(async (response) => {
          let data = await response.json();
          if (data.success) {
            resolve();
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getPopular = () => {
    const header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/stock_stats/top`, header)
        .then(async (response) => {
          const data = await response.json();
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  incSymbolStats = async (symbol) => {
    try {
      const response = await fetch(`${baseUrl}/api/stock_stats/inc/${symbol}`, {
        method: "POST",
        body: "",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.getAccessToken()}`,
        },
      });
      const data = await response.json();
      console.info(`POST /api/stock_stats/inc/${symbol} - response - `, data);
      return data;
    } catch (e) {
      return null;
    }
  };

  getStockPageLink = (domain, stock) => {
    return `${baseUrl}/api/stock/${domain}/${stock}/`;
  };

  getAlertHistory = () => {
    const header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
    return fetch(`${baseUrl}/api/alert_history`, header).then((response) => response.json());
  };

  clearAlertHistory = () => {
    const header = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
    return fetch(`${baseUrl}/api/alert_history`, header).then((response) => response.json());
  };

  handleAuthentication = () => {
    let access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post("/api/auth/register", data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/auth/login", {
          email,
          password,
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token);
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  signInWithToken = () => {
    const header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/auth/user`, header)
        .then(async (response) => {
          const data = await response.json();
          if (data.error) {
            reject(data.error);
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  signInWithGoogle = async (payload) => {
    const response = await axios.post("/api/auth/google", payload);
    if (response.data.user) {
      this.setSession(response.data.access_token);
      return response.data;
    } else {
      throw response.data.error;
    }
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  setDatasourceSession = (token) => {
    if (token) {
      localStorage.setItem("jwt_ds_access_token", token);
    } else {
      localStorage.removeItem("jwt_ds_access_token");
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };

  addAlert = async ({ category, rate, high, low, type }) => {
    const response = await fetch(`${baseUrl}/api/alerts`, {
      method: "POST",
      body: JSON.stringify({
        category,
        rate,
        high,
        low,
        type,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    console.info("POST /api/alerts - response - ", data);
    return data;
  };

  updateAlert = async (id, alert) => {
    const response = await fetch(`${baseUrl}/api/alerts/${id}`, {
      method: "PUT",
      body: JSON.stringify(alert),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    console.info("PUT /api/alerts/:id - response - ", data);
    return data;
  };

  updateHaltAlert = async (type) => {
    const response = await fetch(`${baseUrl}/api/alerts/halt`, {
      method: "POST",
      body: JSON.stringify({ type }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    console.info("POST /api/alerts/halt - response - ", data);
    return data;
  };

  updateUserData = async (user) => {
    const header = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
      body: JSON.stringify(user),
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/auth/user`, header)
        .then(async (response) => {
          let data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  deleteAlert = async (id) => {
    const response = await fetch(`${baseUrl}/api/alerts/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    console.info("DELETE /api/alerts/:id - response - ", data);
    return data;
  };

  getAlerts = async () => {
    const response = await fetch(`${baseUrl}/api/alerts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    console.info("GET /api/alerts - response - ", data);
    return data;
  };

  getQuotes = async () => {
    const response = await fetch(`${baseUrl}/api/quotes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  getHistoricalPrice = async (symbol, timeFrame) => {
    const response = await fetch(`${baseUrl}/api/quotes/${symbol}/price-history?tf=${timeFrame}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    let data = await response.json();
    data.data = data.data.sort((a, b) => a.t - b.t);

    return data;
  };

  registerQuote = async (adds, deletes) => {
    const response = await fetch(`${baseUrl}/api/quotes`, {
      method: "POST",
      body: JSON.stringify({ adds, deletes }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  deleteQuote = async (symbol) => {
    console.info("deleteQuote:", symbol);
    const response = await fetch(`${baseUrl}/api/quotes/${symbol}`, {
      method: "DELETE",
      body: JSON.stringify({ symbol }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    console.info("DELETE /api/quotes - response - ", data);
    return data;
  };

  updateQuotesOrder = async (symbolList) => {
    const response = await fetch(`${baseUrl}/api/quotes/reorder`, {
      method: "POST",
      body: JSON.stringify({ quotes: symbolList }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  resetQuotePin = async (symbol, pin) => {
    console.info("resetQuotePin:", symbol, pin);
    const response = await fetch(`${baseUrl}/api/quotes/pin/${symbol}`, {
      method: "POST",
      body: JSON.stringify({ pin }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  getSetting = async () => {
    const response = await fetch(`${baseUrl}/api/settings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  updateDashboardLayout = async (layout) => {
    const response = await fetch(`${baseUrl}/api/settings/dashboard/layout`, {
      method: "POST",
      body: JSON.stringify({ layout }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  updateSettingConfig = async (config) => {
    const response = await fetch(`${baseUrl}/api/settings/config`, {
      method: "POST",
      body: JSON.stringify({ config }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  updateSettingDiscovery = async (discovery) => {
    const response = await fetch(`${baseUrl}/api/settings/discovery`, {
      method: "POST",
      body: JSON.stringify({ discovery }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  updateSettingFilteredAlerts = async (filtered_alerts) => {
    const response = await fetch(`${baseUrl}/api/alerts/filtered-alerts`, {
      method: "POST",
      body: JSON.stringify({ filtered_alerts }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  validateFilteredAlert = async (alertItem) => {
    const response = await fetch(`${extBaseUrl}/api/alerts/filtered-alert/validate`, {
      method: "POST",
      body: JSON.stringify(alertItem),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  updateSettingAlertWebhook = async (alert_webhook) => {
    const response = await fetch(`${baseUrl}/api/alerts/webhook`, {
      method: "POST",
      body: JSON.stringify({ alert_webhook }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  validateUserSymbols = async (symbols) => {
    const response = await fetch(`${baseUrl}/api/settings/symbols/validate`, {
      method: "POST",
      body: JSON.stringify({ symbols }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  registerPushToken = async (registration_id, deviceType) => {
    try {
      const res = await fetch(`${baseUrl}/api/alerts/device/${deviceType}`, {
        method: "POST",
        body: JSON.stringify({
          registration_id,
        }),
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      console.info("Push Token Registered:", data);
      return data;
    } catch (e) {
      console.error("Failed to register the push token", e);
    }
  };

  // getStats = async () => {
  //   try {
  //     const res = await fetch(STATS_API, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${window.localStorage.getItem(
  //           "jwt_ds_access_token"
  //         )}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     if (res.status === 401) {
  //       await this.datasourceLogin();
  //       return await this.getStats();
  //     } else {
  //       const data = await res.json();
  //       return data;
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     throw e;
  //   }
  // };

  getOptions = async () => {
    try {
      const res = await fetch(OPTIONS_API, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_ds_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      if (res.status === 401) {
        await this.datasourceLogin();
        return await this.getOptions();
      } else {
        const data = await res.json();
        return data;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  getRecentStreams = async () => {
    try {
      const res = await fetch(RECENT_STREAM_API, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_ds_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      if (res.status === 401) {
        await this.datasourceLogin();
        return await this.getRecentStreams();
      } else {
        const data = await res.json();
        return data;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  getHalts = async () => {
    try {
      const res = await fetch(HALT_LIST_API, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_ds_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      if (res.status === 401) {
        await this.datasourceLogin();
        return await this.getHalts();
      } else {
        const data = await res.json();
        return data;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  getMoneyFlowData = async (symbol, timeframe) => {
    try {
      const res = await fetch(`${baseUrl}/api/discovery/money-flow/${symbol}/${timeframe}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (!data || !data.success) {
        throw `GET moneyflow data failed: ${symbol}`;
      }
      return data.data;
    } catch (e) {
      console.error(`GET moneyflow data failed: ${symbol}`, e);
      throw e;
    }
  };

  getVolumeData = async (symbol, timeframe) => {
    try {
      const res = await fetch(`${baseUrl}/api/discovery/real-volume/${symbol}/${timeframe}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (!data || !data.success) {
        throw `GET real volume data failed: ${symbol}`;
      }
      return data.data;
    } catch (e) {
      console.error(`GET real volume data failed: ${symbol}`, e);
      throw e;
    }
  };

  getTradeCountData = async (symbol, timeframe) => {
    try {
      const res = await fetch(`${baseUrl}/api/discovery/trade-count/${symbol}/${timeframe}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (!data || !data.success) {
        throw `GET trade count data failed: ${symbol}`;
      }
      return data.data;
    } catch (e) {
      console.error(`GET trade count data failed: ${symbol}`, e);
      throw e;
    }
  };

  getSectorSymbolsCnt = async () => {
    try {
      const res = await fetch(`${baseUrl}/api/discovery/sector/symbols/cnt`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (!data || !data.success) {
        throw `GET Sector Symbols Count failed!`;
      }
      return data.content;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  getSparkLines = async (symbol) => {
    try {
      const res = await fetch(`${baseUrl}/api/stock_info/sparkline/${symbol}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (!data || !data.success) {
        throw `GET sparklines failed: ${symbol}`;
      }
      return data.items;
    } catch (e) {
      console.error(`GET sparklines failed: ${symbol}`, e);
      throw e;
    }
  };

  getDiscovery = async ({ timeframe }) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
        },
      };
      const response = await Promise.all([
        fetch(`${cdnBaseUrl}/discovery/dev/discovery-${timeframe}.json`, requestOptions),
        // fetch(`${cdnBaseUrl}/discovery/dev/discovery-ext1-${timeframe}.json`, requestOptions),
      ]);
      const data = await response[0].json();
      return data;
    } catch (err) {
      console.error("Failed to fetch discovery data", err);
      return [];
    }
  };

  getDiscoveryFullQuote = async (symbols, timeframe) => {
    try {
      const symbolsStr = symbols.join(",");
      const res = await fetch(`${baseUrl}/api/discovery/quote/full/${timeframe}/${symbolsStr}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (data.success) {
        return data.content;
      } else {
        throw data.error;
      }
    } catch (e) {
      console.error("Failed retrieve full quote", timeframe, symbols);
      throw e;
    }
  };

  getDiscoveryAllByField = async (fields, timeframe) => {
    try {
      const fieldsStr = fields.join(",");
      const res = await fetch(`${baseUrl}/api/discovery/all/${timeframe}/${fieldsStr}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (data.success) {
        return data.content;
      } else {
        throw data.error;
      }
    } catch (e) {
      console.error("Failed retrieve full quote", timeframe, fields);
      throw e;
    }
  };

  getStripePlans = async () => {
    const response = await fetch(`${baseUrl}/api/stripe/plans`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  };

  createCustomer = async (token) => {
    try {
      const res = await fetch(`${baseUrl}/api/stripe/customer`, {
        method: "POST",
        body: JSON.stringify({
          token,
        }),
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      console.info("Stripe Customer Created:", data);
      return data;
    } catch (e) {
      console.error("Failed to create stripe customer", e);
    }
  };

  getCustomer = async () => {
    try {
      const res = await fetch(`${baseUrl}/api/stripe/customer`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      console.info("Stripe Customer:", data);
      return data;
    } catch (e) {
      console.error("Failed to create stripe customer", e);
    }
  };

  createSubscription = async (plan, coupon) => {
    try {
      const res = await fetch(`${baseUrl}/api/stripe/subscription`, {
        method: "POST",
        body: JSON.stringify({ plan, coupon }),
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      console.info("createSubscription:", data);
      return data;
    } catch (e) {
      console.error("Failed to create createSubscription", e);
    }
  };

  cancelSubscription = async (id) => {
    try {
      const res = await fetch(`${baseUrl}/api/stripe/subscription/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      console.info("delete subscription:", data);
      return data;
    } catch (e) {
      console.error("Failed to cancelSubscription", e);
    }
  };

  getCoupon = async (code) => {
    try {
      const res = await fetch(`${baseUrl}/api/stripe/coupon/${code}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      console.info("coupon data:", data);
      return data;
    } catch (e) {
      console.error("Failed to getCoupon", e);
    }
  };

  sendForgotPasswordEmail = async (email) => {
    const header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/auth/forgot_password`, header)
        .then(async (response) => {
          let data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  resetPassword = async (password, token) => {
    const header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        token,
      }),
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/auth/reset_password`, header)
        .then(async (response) => {
          let data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getStripeInvoices = () => {
    const header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
    return fetch(`${baseUrl}/api/stripe/history`, header).then((response) => response.json());
  };

  getNews = ({ symbols, ig_rf, publishedAt, last_id }) => {
    const header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${baseUrl}/api/news?symbols=${symbols || ""}&ig_rf=${
          ig_rf ? "true" : ""
        }&last_id=${last_id}&publishedAt=${publishedAt}`,
        header
      )
        .then(async (response) => {
          const data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getNewsLink = ({ id }) => {
    const header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
      body: JSON.stringify({
        id,
      }),
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/news/extract`, header)
        .then(async (response) => {
          const data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getStockNames = async () => {
    try {
      const response = await fetch(`${cdnBaseUrl}/stock-names/data.json`, {
        method: "GET",
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.error("Failed to fetch stock names data", err);
      return [];
    }
  };

  getUsernames = async () => {
    const header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/users`, header)
        .then(async (response) => {
          const data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getChatChannels = async () => {
    const header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/chat/channels`, header)
        .then(async (response) => {
          const data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getChatLastRead = async (channel_id) => {
    const header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };

    const response = await fetch(`${baseUrl}/api/chat/${channel_id}/last-read`, header);
    const data = await response.json();

    if (!data.success) {
      throw new Error(data.error);
    }

    return data;
  };

  setChatLastRead = async (channel_id, msg_id) => {
    const header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
      body: JSON.stringify({
        msg_id,
      }),
    };

    const response = await fetch(`${baseUrl}/api/chat/${channel_id}/last-read`, header);
    const data = await response.json();

    if (!data.success) {
      throw new Error(data.error);
    }

    return data;
  };

  getChatMessages = async (channel_id, filters) => {
    const header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
    return new Promise((resolve, reject) => {
      let url = `${baseUrl}/api/chat/${channel_id}/messages`;
      let params = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
      if (params) {
        url = `${url}?${params}`;
      }
      fetch(url, header)
        .then(async (response) => {
          const data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  uploadChatImages = async (formData) => {
    const header = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
      body: formData,
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/chat/attachment`, header)
        .then(async (response) => {
          const data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateProfile = async (formData) => {
    const header = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
      body: formData,
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/profile`, header)
        .then(async (response) => {
          const data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getUserProfile = async (payload) => {
    const header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/profile`, header)
        .then(async (response) => {
          const data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateUserProfile = async (payload) => {
    const header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
      body: JSON.stringify(payload),
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/admin/user/update`, header)
        .then(async (response) => {
          const data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getUsers = async () => {
    const header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl}/api/admin/users`, header)
        .then(async (response) => {
          const data = await response.json();
          if (data.success) {
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const instance = new API();

export default instance;
