import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import CsvDownloader from "react-csv-downloader";
import cogoToast from "cogo-toast";
import { connect, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import SmartFiltersModal from "./SmartFiltersModal";
import SearchInput from "../shared/SearchInput";
import ColorBar from "../shared/ColorBar";
import SectorSelector from "../shared/SectorSelector";

import { DashboardActions, DiscoveryActions } from "../store";
import { showAllTableFilter } from "./discoveryReducer";
import { getPersistedTableFilters } from "./DiscoveryUtils";

import {
  SECTORS_FILTER,
  isActiveSubscription,
  isPro,
  isProNew,
  isProPlusNew,
  SHOW_ALL_DISCOVERY_FILTER,
  DISCOVERY_FILTER_TEMP_PREFIX,
  DEFAULT_DISCOVERY_SECTOR,
  SEARCH_DROPDOWN_MODE_SYMBOL,
  DEFAULT_DISCOVERY_SETTING_LIST,
} from "../constants";

import API from "../api";
import TimeframeSelector from "../shared/TimeframeSelector";
import style from "./DiscoveryFilter.module.scss";
import withScreenSizes from "../shared/Utilities/withScreenSizes";
import FaderButton from "./FilterFader/FaderButton";
import { Scrollbars } from "react-custom-scrollbars-2";
import _ from "lodash";
import { encodeDiscoveryFieldForTimeframe, encodeDiscoveryFieldTitleForTimeframe, isColumnAccessible } from "../util";
import { isDiscoveryColumnEnabled } from "../stateUtils";

const DiscoveryFilters = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [sectorDropdownShown, setSectorDropdownShown] = useState(false);

  // const [last4RefreshedTimestamp, setLast4RefreshedTimestamp] = useState([]);
  // const [isManualRefreshing, setIsManualRefreshing] = useState(false);
  // const [countdownTimerKey, setCountdownTimerKey] = useState(1);

  // const countdownTimer = useRef(null);
  const filterModal = useRef(null);
  const userPresetValues = useRef({});

  const {
    widget,
    settingLoaded,
    onChangeDiscoveryFilter,
    addTableFilter,
    updateTableFilter,
    tableDataToExport,
    triggerDataFetch,
    setDiscovery,
    updateSearchDropdown,
    updateSearchDropdownClicked,
    isProOld,
    isProPlus,
    isPro,
    isSmallScreen,
  } = props;

  const config = useSelector((state) => state.config);
  const {
    discoverySector,
    discoveryTimeframe,
    discoveryFilter,
    isFavFilter,
    selectedTableFilter,
    tableFilters,
    discoverySort,
  } = useSelector((state) => state.discovery);

  const persistedTableFilters = getPersistedTableFilters({ tableFilters, selectedTableFilter }, widget);

  const timeframe = discoveryTimeframe[widget];

  const discoveryConfig = (config?.discovery || DEFAULT_DISCOVERY_SETTING_LIST).find((item) => item.id === widget);

  useEffect(() => {
    // if (countdownTimer.current) {
    //   countdownTimer.current.classList.add('active');
    // }

    triggerDataFetch({
      ignoreOldData: true,
    });
  }, [timeframe]);

  useEffect(() => {
    async function saveData() {
      const payload = {
        tableFilters: {
          ...(tableFilters || {}),
        },
      };
      if (!tableFilters) {
        payload.tableFilters = {
          [SHOW_ALL_DISCOVERY_FILTER]: {
            ...showAllTableFilter,
          },
        };
      } else if (!tableFilters[SHOW_ALL_DISCOVERY_FILTER]) {
        payload.tableFilters = {
          ...tableFilters,
          [SHOW_ALL_DISCOVERY_FILTER]: {
            ...showAllTableFilter,
          },
        };
      }

      for (const filterName in payload.tableFilters) {
        if (filterName.startsWith(DISCOVERY_FILTER_TEMP_PREFIX) && filterName !== selectedTableFilter[widget]) {
          delete payload.tableFilters[filterName];
        }
      }

      try {
        const res = await API.updateSettingDiscovery(payload);
        if (!res.success) {
          throw "error";
        }
      } catch (e) {
        cogoToast.error(`Failed to update discovery filters`);
      }
    }

    if (settingLoaded) {
      saveData();
    }
  }, [tableFilters, selectedTableFilter[widget]]);

  const onFilterAdd = (item) => {
    if (persistedTableFilters.indexOf(item.name) > -1) {
      cogoToast.warn(`A filter already exists with the same name`);
      return;
    } else {
      !filterModal || filterModal.current.clear();
      addTableFilter({
        widget,
        filter: item,
      });
      setOpenModal(false);
    }
  };

  const onFilterUpdate = (origName, item) => {
    if (origName != item.name && persistedTableFilters.indexOf(item.name) > -1) {
      cogoToast.warn(`A filter already exists with the same name`);
      return;
    }

    delete tableFilters[origName];
    const newFilter = {
      type: item.type,
      color: item.color,
      values: item.values,
      discPreset: item.discPreset,
    };
    tableFilters[item.name] = newFilter;

    const updatedSelectedTableFilter = {
      ...selectedTableFilter,
    };
    for (const key in updatedSelectedTableFilter) {
      if (updatedSelectedTableFilter[key] === origName || key === widget) {
        updatedSelectedTableFilter[key] = item.name;
      }
    }

    setDiscovery({
      tableFilters: {
        ...tableFilters,
      },
      selectedTableFilter: updatedSelectedTableFilter,
    });

    setTimeout(() => handleDiscPrescUpdate(item.name), 10);

    !filterModal || filterModal.current.clear();
    setOpenModal(false);
  };

  const updateFilter = (name) => {
    if (!tableFilters[name]) {
      return;
    }
    !filterModal || filterModal.current.initForUpdate(name, tableFilters[name]);
    setOpenModal(true);
  };

  const getDiscoverySectorValue = () => {
    if (discoverySector[widget].length === 1) return discoverySector[widget][0];
    const labels = Object.keys(SECTORS_FILTER);
    for (let item of labels) {
      if (discoverySector[widget].includes(item)) {
        return item + ` [${discoverySector[widget].length - 1}]`;
      }
    }
    return "";
  };

  const isDiscoverySectorAll = () => {
    if (!Array.isArray(discoverySector[widget]) || discoverySector[widget].length === 0) {
      return true;
    }
    if (discoverySector[widget].length === 1 && discoverySector[widget][0] === DEFAULT_DISCOVERY_SECTOR) {
      return true;
    }
    return false;
  };

  const getCSVExportColumns = () => {
    const discoveryConfig = config.discovery;
    const activeColumns = discoveryConfig
      .find((item) => item.id === widget)
      ?.value?.filter((item) => item.hidden === false);

    const columns = [
      {
        id: "symbol",
        displayName: "Symbol",
      },
      {
        id: "last",
        displayName: "Last",
      },
      {
        id: "price_dist",
        displayName: "Last %",
      },
      {
        id: "dollar_dist",
        displayName: "Last $",
      },
      {
        id: "volume",
        displayName: "Volume",
      },
      {
        id: "moneyflow",
        displayName: "Money Flow",
      },
      {
        id: "marketCap",
        displayName: "Market Cap",
      },
      {
        id: "float",
        displayName: "Float",
      },
      {
        id: "momentum",
        displayName: "Momentum",
      },
      {
        id: "tradeCount",
        displayName: "Trades",
      },
      {
        id: "uVol",
        displayName: "UVol",
      },
      {
        id: "short_ratio",
        displayName: "Short Ratio",
      },
      {
        id: "vWapDist",
        displayName: "VWap Dist",
      },
      {
        id: "vWapSlope",
        displayName: "Vector",
      },
      {
        id: "squeeze",
        displayName: "Squeeze",
      },
      {
        id: "atr",
        displayName: "ATR %",
      },
      {
        id: "atr_dollar_dist",
        displayName: "ATR $",
      },
      {
        id: "gap_percent_dist",
        displayName: "Gap %",
      },
      {
        id: "gap",
        displayName: "Gap $",
      },
      {
        id: "trend",
        displayName: "Trend",
      },
      {
        id: "rs",
        displayName: "RelStr",
      },
      {
        id: "halt",
        displayName: "Halt",
      },
    ].filter((item) => {
      if (item.id === "squeeze" || item.id === "moneyflow") {
        return isProPlus || isProOld;
      }
      if (item.id === "trend") {
        return isProPlus;
      }
      return true;
    });

    const ordered = [
      {
        id: "symbol",
        displayName: "Symbol",
      },
    ];
    for (let item of activeColumns) {
      const column = columns.find((col) => col.id === item.column);
      if (column) {
        ordered.push({
          id: encodeDiscoveryFieldForTimeframe(item.column, item.timeframe),
          displayName: encodeDiscoveryFieldTitleForTimeframe(column.displayName, item.timeframe),
        });
      }
    }
    return ordered;
  };

  // const isManualRefreshingAvailable = () => {
  //   if (last4RefreshedTimestamp.length < 4) return true;
  //   const now = new Date();
  //   const timediff = now.getTime() - last4RefreshedTimestamp[3].getTime();
  //   return timediff > 60 * 1000;
  // }

  // const onClickManualRefresh = () => {
  //   if (isManualRefreshing) {
  //     return;
  //   }
  //   if (!isManualRefreshingAvailable()) {
  //     return;
  //   }
  //   triggerDataFetch({ manual: true })
  //   setIsManualRefreshing(true);
  //   setTimeout(() => {
  //     setCountdownTimerKey(countdownTimerKey + 1);
  //   }, 1500);
  //   setTimeout(() => {
  //     setIsManualRefreshing(false)
  //     setLast4RefreshedTimestamp([
  //       new Date(),
  //       ...last4RefreshedTimestamp.slice(0, 3)
  //     ]);
  //   }, 2000);
  // }

  // const onCompleteCountdownTimer = () => {
  //   if (!isManualRefreshing) {
  //     triggerDataFetch();
  //     setLast4RefreshedTimestamp([
  //       new Date(),
  //       ...last4RefreshedTimestamp.slice(0, 3)
  //     ]);
  //   }
  //   return {
  //     shouldRepeat:true,
  //     delay: 0
  //   }
  // };

  // useEffect(() => {
  //   triggerDataFetch();
  //   setCountdownTimerKey(countdownTimerKey + 1);
  // }, [timeframe, setCountdownTimerKey]);

  const { symbol: searchSymbol, source: searchSource } = useSelector(
    (state) => state?.dashboard?.searchDropdown?.clicked || {}
  );
  useEffect(() => {
    if (searchSource === widget && searchSymbol) {
      onChangeDiscoveryFilter(searchSymbol, true);
      updateSearchDropdownClicked("");
    }
  }, [searchSource, searchSymbol]);

  const onChangeSearch = (text, boundingRect) => {
    onChangeDiscoveryFilter(text, false);
    const rect = boundingRect;
    updateSearchDropdown({
      mode: SEARCH_DROPDOWN_MODE_SYMBOL,
      visible: true,
      mobileVisible: true,
      mobileSearch: text,
      search: text,
      source: widget, // TODO: get classname
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
    });
  };

  const onClearSearch = (e) => {
    onChangeDiscoveryFilter("", false);
    updateSearchDropdown({
      mode: "",
      visible: false,
      mobileVisible: false,
      mobileSearch: "",
      search: "",
      source: null,
    });
  };

  const onFocusDiscoveryFilter = (e, focused) => {
    if (focused) {
      const rect = e.target.getBoundingClientRect();
      updateSearchDropdown({
        mode: SEARCH_DROPDOWN_MODE_SYMBOL,
        visible: !!discoveryFilter[widget],
        mobileVisible: true,
        mobileSearch: discoveryFilter[widget],
        search: discoveryFilter[widget],
        source: widget, // TODO: get classname
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
        left: rect.left,
      });
    } else {
      if (!isMobile) {
        setTimeout(() => {
          updateSearchDropdown({
            mode: "",
            visible: false,
            mobileVisible: false,
            mobileSearch: "",
            search: "",
            source: null,
          });
        }, 200);
      }
    }
  };

  function updateTimeframe(timeframe) {
    props.updateDiscoveryTimeframe({
      widget,
      timeframe,
    });
  }

  function updateDiscoverySector(sectors) {
    props.setDiscoverySector({
      sectors,
      widget,
    });
  }

  function updateSort(sortBy, sortDirection) {
    props.updateDiscoverySort(
      {
        sortBy,
        sortDirection: sortDirection,
      },
      widget
    );
  }

  function handleFilterSelect(filterName) {
    updateTableFilter({
      widget,
      filterName,
    });
    handleDiscPrescUpdate(filterName);
  }

  function handleDiscPrescUpdate(filterName) {
    const filter = tableFilters[filterName];

    let canSort = false;
    if (filter?.discPreset?.sort?.enabled && filter?.discPreset?.sort?.sortBy && filter?.discPreset?.sort?.order) {
      const sortBy = filter.discPreset.sort.sortBy;
      if (
        isColumnAccessible(sortBy, {
          isProPlus,
          isProOld,
          isPro,
        })
      ) {
        if (isDiscoveryColumnEnabled(widget, sortBy)) {
          canSort = true;
        }
      }
    }

    // store user set values to restore on filter change
    if (filter?.discPreset?.sector?.enabled && filter?.discPreset?.sector?.selected?.length > 0) {
      if (!userPresetValues.current?.sectors) {
        userPresetValues.current = {
          ...userPresetValues.current,
          sectors: discoverySector[widget],
        };
      }
    }
    if (filter?.discPreset?.timeframe?.enabled && filter?.discPreset?.timeframe?.value) {
      if (!userPresetValues.current?.timeframe) {
        userPresetValues.current = {
          ...userPresetValues.current,
          timeframe,
        };
      }
    }
    if (canSort) {
      if (!userPresetValues.current?.sort) {
        userPresetValues.current = {
          ...userPresetValues.current,
          sort: discoverySort[widget],
        };
      }
    }

    //Update discPresets
    const changed = { timeframe: false, sectors: false, sort: false };
    if (filter?.discPreset) {
      if (filter.discPreset?.timeframe?.enabled && filter.discPreset.timeframe?.value) {
        props.updateDiscoveryTimeframe({
          widget,
          timeframe: filter.discPreset.timeframe.value,
        });
        changed.timeframe = true;
      }

      if (filter.discPreset?.sector?.enabled && filter.discPreset.sector?.selected) {
        updateDiscoverySector(filter.discPreset.sector.selected);
        changed.sectors = true;
      }

      if (canSort) {
        updateSort(filter.discPreset.sort.sortBy, filter.discPreset.sort.order);
        changed.sort = true;
      }
    }

    //Restore user values
    if (userPresetValues.current.timeframe) {
      if (!changed.timeframe && timeframe !== userPresetValues.current.timeframe) {
        props.updateDiscoveryTimeframe({
          widget,
          timeframe: userPresetValues.current.timeframe,
        });
      }
    }

    if (userPresetValues.current.sectors) {
      if (!changed.sectors && !_.isEqual(discoverySector[widget], userPresetValues.current.sectors)) {
        updateDiscoverySector(userPresetValues.current.sectors);
      }
    }

    if (userPresetValues.current.sort) {
      if (!changed.sort && !_.isEqual(discoverySort[widget], userPresetValues.current.sort)) {
        updateSort(userPresetValues.current.sort.sortBy, userPresetValues.current.sort.sortDirection);
      }
    }
  }

  return (
    <>
      <SmartFiltersModal
        widget={widget}
        config={discoveryConfig.value}
        forwardRef={filterModal}
        open={openModal}
        setOpenModal={setOpenModal}
        onFilter={onFilterAdd}
        onUpdate={onFilterUpdate}
        isPro={props.isPro}
        isProOld={props.isProOld}
        isProPlus={props.isProPlus}
      />
      <div className="d-flex justify-content-end justify-content-md-between flex-row flex-wrap flex-grow-1">
        <h4
          className="discovery-header d-flex align-items-center card-title mb-1 py-1"
          style={{ flex: "1", position: "relative" }}
        >
          <div className="discovery-label-container d-flex">
            <ColorBar className="ml-n2 mr-2" color={tableFilters[selectedTableFilter[widget]].color} />
            <span className="d-flex discovery-label discovery-label">
              {discoveryConfig.title}
              {/* <span
                style={{
                  paddingLeft: 2,
                  paddingRight: 2,
                  fontSize: "10px",
                  color: "#000000",
                  background: "#ffff",
                  marginLeft: "5px",
                  height: "11px",
                }}
              >
                PRO
              </span> */}
              <CsvDownloader
                className="btn-csv-downloader"
                filename="discovery"
                separator=","
                wrapColumnChar=""
                columns={getCSVExportColumns()}
                datas={tableDataToExport}
              >
                <img src="/download.png" width="18" height="18" />
              </CsvDownloader>
            </span>
            {!!Object.keys(tableFilters?.[selectedTableFilter?.[widget]]?.values ?? {}).length && (
              <FaderButton widget={widget} />
            )}
          </div>
          {/* <div className="discovery-countdown-timer" ref={countdownTimer}>
            <div className="pie spinner"></div>
            <div className="pie filler"></div>
            <div className="mask"></div>
          </div> */}
          {/* <div className={`discovery-timer ml-3 ${isManualRefreshingAvailable() ? '' : 'manual-disabled'} ${isManualRefreshing ? 'refreshing' : ''}`} onClick={onClickManualRefresh}>
            <div className="countdown-circle-timer">
              <CountdownCircleTimer
                key={countdownTimerKey}
                isPlaying
                duration={60}
                size={26}
                strokeWidth={2}
                colors={[
                  '#FFFFFF'
                ]}
                trailColor={"#191c24"}
                onComplete={onCompleteCountdownTimer}
              >
                {({ remainingTime }) => {
                  // if (remainingTime > 15) return null;
                  return <span style={{fontSize: "12px"}}>{remainingTime}</span>
                }}
              </CountdownCircleTimer>
            </div>
            <div className="discovery-manual-refresher">
              <i className="fa fa-repeat" />
              <svg x="0px" y="0px" viewBox="0 0 490.563 490.563">
                <g>
                  <path d="M331.004,128.766c-2.1,11.4,5.2,21.8,16.6,23.9l102,17.7c12.1,1.9,20.1-6.6,22.9-17.7l17.7-102
                      c2.1-11.4-5.2-21.8-16.6-23.9s-21.8,5.2-23.9,16.6l-9,54.7c-45.7-60.7-117.9-97.8-195.8-97.9c-146.7,0-243.9,116.3-244.9,244.9
                      c-0.5,65.4,49.8,232.9,244.8,244.8c195.2,11.9,244.8-179.4,244.8-244.8c0-11.3-9.2-20.5-20.5-20.5s-20.5,9.2-20.5,20.5
                      c0,112.4-91.4,203.8-203.8,203.8s-203.8-91.4-203.8-203.8s91.4-203.8,203.8-203.8c63.9,0,123.3,30.1,161.4,79.3l-51.2-8.5
                      C343.504,109.966,333.104,117.266,331.004,128.766z"/>
                </g>
              </svg>
            </div>
          </div> */}
        </h4>
        <div className="discovery-filter-left d-flex flex-row justify-content-end my-1">
          <div className="filter-bar-wrapper filter-bar-wrapper-hover">
            <SectorSelector
              selectedSectors={discoverySector[widget]}
              handleMultiSelect
              onChange={(sectors) => {
                updateDiscoverySector(sectors);
                userPresetValues.current = {
                  ...userPresetValues.current,
                  sectors,
                };
              }}
            />
          </div>

          <div className="filter-bar-wrapper filter-bar-wrapper-hover">
            <TimeframeSelector
              timeframe={timeframe}
              setTimeframe={(timeframe) => {
                updateTimeframe(timeframe);
                userPresetValues.current = {
                  ...userPresetValues.current,
                  timeframe,
                };
              }}
            />
          </div>

          <div
            className="filter-bar-wrapper filter-bar-wrapper-hover py-2 px-0 ml-2 d-flex align-items-center"
            role="button"
          >
            <Dropdown varaint="btn btn-outline-secondary" className={"d-flex align-items-center "}>
              <Dropdown.Toggle
                className="industry_input d-flex align-items-center"
                style={{ paddingLeft: 5, marginTop: 1 }}
              >
                <i
                  className="mdi mdi-filter"
                  style={selectedTableFilter[widget] === SHOW_ALL_DISCOVERY_FILTER ? {} : { color: "#f6c548" }}
                />
                <span className="d-none d-sm-block">
                  {selectedTableFilter[widget] === SHOW_ALL_DISCOVERY_FILTER
                    ? "No Filter"
                    : selectedTableFilter[widget]}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Scrollbars
                  autoHide
                  className={style.ScrollBars}
                  renderTrackVertical={(props) => <div className="track-vertical" {...props} />}
                  style={{ backgroundColor: "inherit" }}
                >
                  <Dropdown.Item
                    className="d-flex justify-content-between px-3"
                    onClick={() => {
                      !filterModal || filterModal.current.initForAdd();
                      setOpenModal(true);
                    }}
                  >
                    Add New
                    <i className={"mdi mdi-plus " + style.spin} />
                  </Dropdown.Item>
                  {persistedTableFilters.map((filterName, i) => (
                    <Dropdown.Item
                      key={`filter-${i}-${filterName}`}
                      onClick={(event) => {
                        handleFilterSelect(filterName);
                      }}
                      tabIndex="1"
                      className="d-flex px-3"
                    >
                      <ColorBar color={tableFilters[filterName].color} className="ml-n2 mr-1" />
                      <div className="d-flex justify-content-between flex-grow">
                        <span
                          style={{
                            color: `${filterName === selectedTableFilter[widget] ? "#f6c548" : ""}`,
                          }}
                        >
                          {filterName === SHOW_ALL_DISCOVERY_FILTER ? "No Filter" : filterName}
                        </span>
                        {i > 0 ? (
                          <span>
                            <i
                              className="mdi mdi-pencil ml-1 mr-3"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                updateFilter(filterName);
                              }}
                            />
                            <i
                              className="mdi mdi-delete"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                props.removeTableFilter(filterName);
                              }}
                            />
                          </span>
                        ) : null}
                      </div>
                    </Dropdown.Item>
                  ))}
                </Scrollbars>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div
            className="filter-bar-wrapper filter-bar-wrapper-hover py-2 pl-0 pr-2 ml-2 d-flex align-items-center"
            onClick={() => props.toggleFavFilter(widget)}
            role="button"
            style={{ ...(isSmallScreen && { order: -1 }) }}
          >
            <i className={`${isFavFilter[widget] ? "mdi mdi-star quote-star" : "mdi mdi-star text-white"}`} />
            <span className="ml-1 font-15">{isSmallScreen ? "Fav" : "Favorites"}</span>
          </div>
        </div>

        <div className="discovery-filter-right d-flex flex-row align-items-center">
          <SearchInput
            className={"flex-grow-1 ml-0 ml-sm-2 mr-0"}
            value={discoveryFilter[widget]}
            placeholder="Symbol..."
            onChange={onChangeSearch}
            onFocus={(e) => onFocusDiscoveryFilter(e, true)}
            onBlur={(e) => onFocusDiscoveryFilter(e, false)}
            onClear={onClearSearch}
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  updateDiscoveryTimeframe: DiscoveryActions.updateDiscoveryTimeframe,
  toggleFavFilter: DiscoveryActions.toggleFavFilter,
  toggleDiscoverySector: DiscoveryActions.toggleDiscoverySector,
  updateTableFilter: DiscoveryActions.updateTableFilter,
  removeTableFilter: DiscoveryActions.removeTableFilter,
  addTableFilter: DiscoveryActions.addTableFilter,
  setDiscovery: DiscoveryActions.setDiscovery,
  updateSearchDropdown: DashboardActions.updateSearchDropdown,
  updateSearchDropdownClicked: DashboardActions.updateSearchDropdownClicked,
  setDiscoverySector: DiscoveryActions.setDiscoverySector,
  updateDiscoverySort: DiscoveryActions.updateDiscoverySort,
};

const mapStateToProps = (state, props) => ({
  ...props,
  isPro:
    isActiveSubscription(state.auth.user.subscription) &&
    (isPro(state.auth.user.subscription.plan) || isProNew(state.auth.user.subscription.plan)),
  isProOld: isActiveSubscription(state.auth.user.subscription) && isPro(state.auth.user.subscription.plan),
  isProPlus: isActiveSubscription(state.auth.user.subscription) && isProPlusNew(state.auth.user.subscription.plan),
});

export default connect(mapStateToProps, mapDispatchToProps)(withScreenSizes(DiscoveryFilters));
